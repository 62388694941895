import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TablePagination } from "@mui/material";

import './LeaveTimeoff.css';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../Redux/selector';
import ApplyLeave from '../../../Components/UserComponents/LeaveTimeoff/ApplyLeave/ApplyLeave';
import { getAppliedLeaves } from '../../../utils/Services/leaveServices';
import { useParams } from 'react-router-dom';

function LeaveTimeoff() {

  const [appliedLeaves, setAppliedLeaves] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const { userId } = useParams();

  const subscriberId = user?.companyId?._id

  const statistics = [
    { title: 'Earned', value: 10, maxValue: 10, color: '#007bff' },
    { title: 'Casual', value: 10, maxValue: 13, color: '#28a745' },
    { title: 'Sick', value: 8, maxValue: 10, color: '#ffc107' },
    { title: 'Special', value: 14, maxValue: 20, color: '#6f42c1' },
    { title: 'Sabbatical', value: 11, maxValue: 17, color: '#fd7e14' },
  ];

  const fetchAppliedLeaves = async () => {
    setLoading(true);
    try {
      const response = await getAppliedLeaves(token, subscriberId, userId);
      setAppliedLeaves(response?.data?.appliedLeaves);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppliedLeaves();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "success";
      case "Pending":
        return "warning";
      case "Rejected":
        return "error";
      default:
        return "default";
    }
  };

  const formatDate = (date) => {
    if (!date) return '-';
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setSelectedLeave(null)
    fetchAppliedLeaves()
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedLeave(null)
    fetchAppliedLeaves()
  };

  const handleRowClick = (leave) => {
    setSelectedLeave(leave);
    setOpenModal(true);
  };

  return (
    <div className='main-container' style={{ backgroundColor: '#f1f1f1' }}>
      <div className="header-container">
        <h1 className="header-title">Leave Availability</h1>
        <button className="apply-button" onClick={handleOpenModal}>Apply Leave</button>
      </div>
      {/* <div className="statistics-container">
        {statistics.map((stat, index) => (
          <div key={index} className="stat-card">
            <div className='stat-container'>
              <h4 className="stat-remaining">{stat.title} Leave</h4>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <div className="progress-wrapper">
                  <CircularProgressbar
                    value={stat.value}
                    maxValue={stat.maxValue}
                    text={`‎${stat.value}/${stat.maxValue}`}
                    styles={buildStyles({
                      textSize: '24px',
                      pathColor: stat.color,
                      textColor: '#212529',
                      trailColor: '#f5f5f5',
                    })}
                  />
                </div>
                <div className="stat-details-wrapper">
                  <p className="stat-details">Available - {stat.value}</p>
                  <p className="stat-details">Used - {stat.maxValue - stat.value}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}
      <div className="main-content-container" style={{ display: 'flex', gap: '20px' }}>
        <TableContainer component={Paper} style={{ marginTop: "20px", boxShadow: "3px 3px 10px rgba(0,0,0,0.1)", borderRadius: "10px", overflow: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                <TableCell style={{ fontWeight: "bold" }}>Applied Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Leave Type</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Start Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>End Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appliedLeaves.map((request, index) => (
                <TableRow key={request.id}
                  style={{
                    cursor: "pointer",
                    backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f1f1f1")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#fafafa" : "white")}
                  onClick={() => handleRowClick(request)}
                >
                  <TableCell>{formatDate(request?.createdAt)}</TableCell>
                  <TableCell>{request?.leaveType}</TableCell>
                  <TableCell>{formatDate(request?.startDate)}</TableCell>
                  <TableCell>{formatDate(request?.endDate)}</TableCell>
                  <TableCell>
                    <Chip label={request.status} color={getStatusColor(request.status)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={appliedLeaves.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
      <ApplyLeave openModal={openModal} handleCloseModal={handleCloseModal} selectedLeave={selectedLeave} />
      {loading && <Loader />}
    </div>
  );
}

export default LeaveTimeoff;
