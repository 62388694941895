import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/leaves`;

// To Create Leave Type
export const createLeaveTypes = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/create-leave-types/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get Leave Type
export const getLeaveTypes = (token, subscriberId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-leave-types/${subscriberId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Delete Leave Type
export const deleteLeaveType = (token, leaveTypeId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.delete(`${backEndUrl}/delete-leave-types/${leaveTypeId}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Update a Leave Type
export const updateLeaveType = (token, leaveTypeId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.put(`${backEndUrl}/update-leave-type/${leaveTypeId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// Apply Leave
export const applyLeave = (token, userId, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/apply-leave/${userId}/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Get Applied Leaves
export const getAppliedLeaves = (token, subscriberId, userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/applied-leaves/${subscriberId}${userId ? `/${userId}` : ""}`, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// To Approve/Reject leave
export const approveRejectLeave = (token, leaveId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/approve-reject-leave/${leaveId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};

// Get leave balance by userId and year (optionally month)
export const getLeaveBalance = (token, userId, year, month) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.get(`${backEndUrl}/get-leave-balance/${userId}`, {
                params: { year, month },
                headers: headers
            });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};