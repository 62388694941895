import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { Box, Button, Modal, TextField, Typography, MenuItem, FormControl, InputLabel, Select, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { applyLeave, getLeaveTypes } from '../../../../utils/Services/leaveServices';

function ApplyLeave({ openModal, handleCloseModal, selectedLeave }) {
    const [data, setData] = useState([]);
    const [leaveType, setLeaveType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [noOfDays, setNoOfDays] = useState(0);
    const [contactNumber, setContactNumber] = useState('');
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const { userId } = useParams();
    const subscriberId = user?.companyId?._id

    const fetchleaveTypeDetail = async () => {
        setLoading(true);
        try {
            const response = await getLeaveTypes(token, subscriberId);
            setData(response?.data?.leaveTypes);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch data',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            })
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchleaveTypeDetail();
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setNoOfDays(diffDays);
        } else {
            setNoOfDays(0);
        }
    }, [startDate, endDate]);

    const handleSubmit = async () => {
        try {
            const leaveData = {
                leaveType,
                startDate,
                endDate,
                noOfDays,
                contactNumber,
                reason,
            };

            await applyLeave(token, userId, subscriberId, leaveData);

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Leave applied successfully!',
                confirmButtonColor: '#1B4965',
            });

        } catch (error) {
            console.error("Error applying leave:", error);
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Error applying leave. Please try again.',
                confirmButtonColor: '#1B4965',
            });
        } finally {
            handleCloseModal();
        }
    };

    return (
        <>
            <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '30%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative', left: 0, transition: 'transform 0.3s ease-in-out', transform: openModal ? 'translateX(0)' : 'translateX(-100%)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '15px' }}>
                        <Typography variant="h6" sx={{ color: '#1B4965' }}>
                            {selectedLeave ? 'Applied leave' : 'Apply Leave'}
                        </Typography>
                        <Button onClick={handleCloseModal} variant="contained"
                            sx={{
                                backgroundColor: '#1B4965', color: 'white',
                                '&:hover': {
                                    backgroundColor: '#1B4965',
                                },
                                fontSize: '12px'
                            }}
                        >Back
                        </Button>
                    </Box>

                    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl required fullWidth variant="outlined" margin="dense">
                            <InputLabel id="leaveType">Leave Type</InputLabel>
                            <Select labelId="leaveType" id="leaveType" name="leaveType" label="Leave Type"
                                value={selectedLeave ? selectedLeave.leaveType : leaveType}
                                onChange={(e) => setLeaveType(e.target.value)}
                                disabled={!!selectedLeave}
                            >
                                {data.map((leave) => (
                                    <MenuItem key={leave._id} value={leave.leaveTypeName}>
                                        {leave.leaveTypeName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <TextField required fullWidth label="From Date" type="date" InputLabelProps={{ shrink: true }} disabled={!!selectedLeave}
                                value={selectedLeave ? selectedLeave.startDate.split("T")[0] : startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <TextField required fullWidth label="To Date" type="date" InputLabelProps={{ shrink: true }} disabled={!!selectedLeave}
                                value={selectedLeave ? selectedLeave.endDate.split("T")[0] : endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Box>

                        <TextField required label="No. of Days" type="number"
                            value={selectedLeave ? selectedLeave.noOfDays : noOfDays}
                            InputProps={{ readOnly: true }}
                        />

                        <TextField required label="Emergency Contact Number" type="tel" disabled={!!selectedLeave}
                            value={selectedLeave ? selectedLeave.contactNumber : contactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                        />

                        <TextField required label="Reason" multiline rows={4} disabled={!!selectedLeave}
                            value={selectedLeave ? selectedLeave.reason : reason}
                            onChange={(e) => setReason(e.target.value)}
                        />

                        {selectedLeave && (
                            <>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Approve / Reject</FormLabel>
                                    <RadioGroup row
                                        value={selectedLeave?.status}
                                    >
                                        <FormControlLabel value="Approved" control={<Radio disabled={!!selectedLeave} />} label="Approve" />
                                        <FormControlLabel value="Rejected" control={<Radio disabled={!!selectedLeave} />} label="Reject" />
                                    </RadioGroup>
                                </FormControl>

                                <TextField label="Comment" value={selectedLeave?.comment}
                                    disabled={!!selectedLeave}
                                    multiline
                                    rows={3}
                                    fullWidth
                                />
                            </>
                        )}

                        {!selectedLeave && (
                            <Button variant="contained" onClick={handleSubmit}
                                sx={{
                                    backgroundColor: '#1B4965',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#1B4965' }
                                }}
                            >Submit
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>
            {(loading) && (
                <Loader />
            )}
        </>
    )
}

export default ApplyLeave;