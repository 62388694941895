import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChromePicker } from 'react-color';

import Swal from 'sweetalert2';
import { Box, Button, Modal, Typography, Tabs, Tab, Divider, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, FormControlLabel, RadioGroup, Radio } from '@mui/material';

import { selectToken } from '../../../../../../Redux/selector';
import Loader from '../../../../../UiUxComponents/Loader/Loader';
import { updateLeaveType } from '../../../../../../utils/Services/leaveServices';

function ViewLeaveType({ open, onClose, leaveType }) {
    const [tabIndex, setTabIndex] = useState(0);
    const [leaveTypeName, setLeaveTypeName] = useState('');
    const [description, setDescription] = useState('');
    const [leaveTypeColor, setLeaveTypeColor] = useState('#ffffff');
    const [leaveConfig, setLeaveConfig] = useState({
        addLeaveManually: false,
        maxLeaves: '',
        leaveAdditionType: '',
        monthlyAddition: '',
        excludeMonths: [],
        carryForward: false,
        applicationNotice: '',
    });
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (leaveType && typeof leaveType === 'object') {
            setLeaveTypeName(leaveType?.leaveTypeName || '');
            setDescription(leaveType?.description || '');
            setLeaveTypeColor(leaveType?.leaveTypeColor || '#ffffff');
            setLeaveConfig({
                addLeaveManually: leaveType?.leaveConfig?.addLeaveManually || false,
                maxLeaves: leaveType?.leaveConfig?.maxLeaves || '',
                leaveAdditionType: leaveType?.leaveConfig?.leaveAdditionType || '',
                monthlyAddition: leaveType?.leaveConfig?.monthlyAddition || '',
                excludeMonths: leaveType?.leaveConfig?.excludeMonths || [],
                carryForward: leaveType?.leaveConfig?.carryForward || false,
                applicationNotice: leaveType?.leaveConfig?.applicationNotice || '',
            });
        }
    }, [leaveType]);

    useEffect(() => {
        if (!open) {
            setTabIndex(0);
        }
    }, [open]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const updatedData = {
                leaveTypeName: leaveTypeName,
                description,
                leaveTypeColor: leaveTypeColor,
                leaveConfig,
            };
            await updateLeaveType(token, leaveType?._id, updatedData);
            Swal.fire({
                icon: 'success',
                title: 'Leave Type Updated Successfully',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (error) {
            console.error('Error updating leave type:', error);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to update leave type',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: 5 }}>
                <Box sx={{ width: '30%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography variant="h6" sx={{ color: '#1B4965' }}>Update / Configure Leave Type</Typography>
                        <Button onClick={onClose} variant="contained" sx={{ backgroundColor: '#1B4965', color: 'white' }}>
                            Back
                        </Button>
                    </Box>

                    <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                        <Tab label="Info" />
                        <Tab label="Configure" />
                    </Tabs>
                    <Divider sx={{ marginTop: '10px' }} />

                    <Box sx={{ marginTop: '10px', overflowY: 'auto', height: '90vh', paddingTop: 2 }}>
                        {tabIndex === 0 && (
                            <Box>
                                <TextField label="Leave Type Name" variant="outlined" fullWidth required
                                    sx={{ marginBottom: 2 }}
                                    value={leaveTypeName}
                                    onChange={(e) => setLeaveTypeName(e.target.value)}
                                />
                                <TextField label="Description" variant="outlined" fullWidth required multiline rows={4}
                                    sx={{ marginBottom: 2 }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                                <Typography sx={{ marginBottom: 1 }}>Leave Type Color</Typography>
                                <ChromePicker color={leaveTypeColor}
                                    onChangeComplete={(color) => setLeaveTypeColor(color.hex)}
                                    disableAlpha={true}
                                />
                                <Button variant="contained" sx={{ backgroundColor: '#1B4965', color: 'white', marginTop: 2 }} onClick={handleSave}>
                                    Update
                                </Button>
                            </Box>
                        )}

                        {tabIndex === 1 && (
                            <Box>
                                <Typography variant="h6" sx={{ marginBottom: '10px' }}>Configure Leave Type</Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={leaveConfig.addLeaveManually}
                                            onChange={(e) => setLeaveConfig({ ...leaveConfig, addLeaveManually: e.target.checked })}
                                        />
                                    }
                                    label="Add Leave Manually"
                                    sx={{ mb: 2 }}
                                />
                                <TextField label="Maximum Leaves per Year" type="number" variant="outlined" fullWidth sx={{ mb: 2 }}
                                    value={leaveConfig.maxLeaves}
                                    onChange={(e) => setLeaveConfig({ ...leaveConfig, maxLeaves: parseInt(e.target.value) })}
                                />
                                <FormControl component="fieldset" sx={{ mb: 2 }}>
                                    <Typography sx={{ marginBottom: 1 }}>Leave Addition Type</Typography>
                                    <RadioGroup
                                        value={leaveConfig.leaveAdditionType}
                                        onChange={(e) => setLeaveConfig({ ...leaveConfig, leaveAdditionType: e.target.value })}
                                    >
                                        <FormControlLabel value="startOfYear" control={<Radio />} label="Add all leaves at the start of the year" />
                                        <FormControlLabel value="monthly" control={<Radio />} label="Add monthly" />
                                    </RadioGroup>
                                </FormControl>
                                {leaveConfig?.leaveAdditionType === 'monthly' && (
                                    <>
                                        <TextField label="No. of Leaves to Add Every Month" type="number" variant="outlined" fullWidth sx={{ mb: 2 }}
                                            value={leaveConfig.monthlyAddition}
                                            onChange={(e) => setLeaveConfig({ ...leaveConfig, monthlyAddition: parseInt(e.target.value) })}
                                        />
                                        <FormControl fullWidth variant="outlined" margin='dense' sx={{ marginBottom: 2 }}>
                                            <InputLabel id='excludeMonths'>Exclude Months</InputLabel>
                                            <Select labelId='excludeMonths' id='excludeMonths' name='excludeMonths' label="excludeMonths" multiple value={leaveConfig.excludeMonths} margin='dense'
                                                onChange={(e) => setLeaveConfig({ ...leaveConfig, excludeMonths: e.target.value })}
                                                renderValue={(selected) => selected.join(', ')}
                                            >
                                                {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => (
                                                    <MenuItem key={month} value={month}>
                                                        <Checkbox checked={leaveConfig.excludeMonths.includes(month)} />
                                                        <ListItemText primary={month} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </>
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={leaveConfig.carryForward}
                                            onChange={(e) => setLeaveConfig({ ...leaveConfig, carryForward: e.target.checked })}
                                        />
                                    }
                                    label="Allow Carry Forward"
                                    sx={{ mb: 2 }}
                                />
                                <TextField label="Application Notice Period (days)" type="number" variant="outlined" fullWidth
                                    value={leaveConfig.applicationNotice}
                                    onChange={(e) => setLeaveConfig({ ...leaveConfig, applicationNotice: parseInt(e.target.value) })}
                                />
                                <Button variant="contained" sx={{ backgroundColor: '#1B4965', color: 'white', marginTop: 2 }} onClick={handleSave}>
                                    Update
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>

            </Modal>
            {(loading) && (
                <Loader />
            )}
        </>
    );
}

export default ViewLeaveType;
