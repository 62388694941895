import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Swal from 'sweetalert2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Modal, Box, Typography, Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TablePagination } from "@mui/material";

import Loader from "../../../UiUxComponents/Loader/Loader";
import { selectToken } from "../../../../Redux/selector";
import { approveRejectLeave, getAppliedLeaves } from "../../../../utils/Services/leaveServices";

function LeaveRequests() {
    const [appliedLeaves, setAppliedLeaves] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedLeave, setSelectedLeave] = useState(null);
    const [approvalStatus, setApprovalStatus] = useState('');
    const [comment, setComment] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    const fetchAppliedLeaves = async () => {
        setLoading(true);
        try {
            const response = await getAppliedLeaves(token, subscriberId);
            setAppliedLeaves(response?.data?.appliedLeaves);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Failed to fetch data',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAppliedLeaves();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Approved":
                return "success";
            case "Pending":
                return "warning";
            case "Rejected":
                return "error";
            default:
                return "default";
        }
    };

    const formatDate = (date) => {
        if (!date) return '-';
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    };

    const handleRowClick = (leave) => {
        setSelectedLeave(leave);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedLeave(null);
        fetchAppliedLeaves();
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const leaveData = {
                status: approvalStatus,
                comment,
            };

            await approveRejectLeave(token, selectedLeave?._id, leaveData);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Leave status updated successfully!',
                confirmButtonColor: '#1B4965',
            });

        } catch (error) {
            console.error("Error updating leave status:", error);
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Error updating leave status. Please try again.',
                confirmButtonColor: '#1B4965',
            });
        } finally {
            setLoading(false);
            handleCloseModal();
            fetchAppliedLeaves();
        }
    };

    return (
        <div style={{ padding: "20px", height: "70vh", overflowY: "auto" }}>
            <TableContainer component={Paper} style={{ marginTop: "20px", boxShadow: "3px 3px 10px rgba(0,0,0,0.1)", borderRadius: "10px", overflow: "hidden" }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell style={{ fontWeight: "bold" }}>Applied Data</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Employee Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Leave Type</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Start Date</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>End Date</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appliedLeaves.map((request, index) => (
                            <TableRow key={request.id}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
                                    transition: "background-color 0.3s ease",
                                }}
                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f1f1f1")}
                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#fafafa" : "white")}
                                onClick={() => handleRowClick(request)}
                            >
                                <TableCell>{formatDate(request?.createdAt)}</TableCell>
                                <TableCell>{request?.userId?.fullName}</TableCell>
                                <TableCell>{request?.leaveType}</TableCell>
                                <TableCell>{formatDate(request?.startDate)}</TableCell>
                                <TableCell>{formatDate(request?.endDate)}</TableCell>
                                <TableCell>
                                    <Chip label={request.status} color={getStatusColor(request.status)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={appliedLeaves.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Box sx={{ width: '30%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative', left: 0, transition: 'transform 0.3s ease-in-out', transform: openModal ? 'translateX(0)' : 'translateX(-100%)', overflowY: 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '15px' }}>
                        <Typography variant="h6" sx={{ color: '#1B4965' }}>Applied Leave</Typography>
                        <Button onClick={handleCloseModal} variant="contained"
                            sx={{
                                backgroundColor: '#1B4965', color: 'white',
                                '&:hover': { backgroundColor: '#1B4965' },
                                fontSize: '12px'
                            }}
                        >Back</Button>
                    </Box>

                    {selectedLeave && (
                        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <TextField label="Employee Name" value={selectedLeave?.userId?.fullName} fullWidth disabled />
                            <TextField label="Leave Type" value={selectedLeave?.leaveType} fullWidth disabled />

                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <TextField label="Start Date" value={formatDate(selectedLeave?.startDate)} fullWidth disabled />
                                <TextField label="End Date" value={formatDate(selectedLeave?.endDate)} fullWidth disabled />
                            </Box>

                            <TextField label="No. of Days" value={selectedLeave?.noOfDays} fullWidth disabled />
                            <TextField label="Contact Number" value={selectedLeave?.contactNumber} fullWidth disabled />
                            <TextField label="Reason" value={selectedLeave?.reason} multiline rows={3} fullWidth disabled />

                            <FormControl component="fieldset">
                                <FormLabel component="legend">Approve / Reject</FormLabel>
                                <RadioGroup row value={approvalStatus || selectedLeave?.status} onChange={(e) => setApprovalStatus(e.target.value)}>
                                    <FormControlLabel value="Approved" control={<Radio />} label="Approve" />
                                    <FormControlLabel value="Rejected" control={<Radio />} label="Reject" />
                                </RadioGroup>
                            </FormControl>

                            <TextField label="Comment" value={comment || selectedLeave?.comment} onChange={(e) => setComment(e.target.value)} multiline rows={3} fullWidth />

                            <Button variant="contained" onClick={handleSubmit}
                                sx={{
                                    backgroundColor: '#1B4965', color: 'white',
                                    '&:hover': { backgroundColor: '#1B4965' },
                                    fontSize: '14px'
                                }}
                            >Submit</Button>
                        </Box>
                    )}
                </Box>
            </Modal>
            {loading && <Loader />}
        </div>
    );
}

export default LeaveRequests;