import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { Box, TextField, Button, Popover, Autocomplete } from '@mui/material';

import { selectToken } from '../../../Redux/selector';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { getAllUsersDetails, getuserDetailsByTabName } from '../../../utils/Services/userServices';

const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/soom-hub`;

function Reports() {
  const [dailyReportData, setDailyReportData] = useState([]);
  const [officeInformationDetails, setOfficeInformatinDetails] = useState(null)
  const [taskData, setTaskData] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({ fromDate: '', toDate: '', specificDate: '', userId: '', employeeId: '' });
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();
  const tabName = "companyDetails"

  // To fetch User Daily Reports
  const fetchUserDailyReports = async () => {
    if (!filters.employeeId) {
      Swal.fire({
        icon: 'warning',
        title: 'Employee Code Missing',
        text: 'Employee code is not provided. Please contact the administrator.',
      });
      return; // Exit the function early
    }

    setLoading(true);
    try {
      // const response = await getUserDailyReports(token, subscriberId, filters);
      const response = await axios.post(`${backEndUrl}/get-daily-report-data`, filters);
      const data = response?.data?.data || []
      setTaskData(data)
      const flattendedData = data.flatMap(employee =>
        employee.tasks.map(task => ({
          ...task,
          submittedDate: employee.submittedDate,
          employeeId: employee.employeeId,
          employeeName: employee.employeeName,
        }))
      )
      setDailyReportData(flattendedData)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        Swal.fire({
          icon: 'info',
          title: 'No Reports Found',
          text: 'No reports were found for the selected employee.',
        });
      } else {
        console.error('Error fetching report data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch report data!',
        });
      }
      setDailyReportData([]);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (triggerFetch) {
      fetchUserDailyReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetch]);

  // Fetch user details
  const fetchUserDetail = async () => {
    setLoading(true);
    try {
      const response = await getAllUsersDetails(token, subscriberId);
      setUsers(response?.data?.data || []);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error. Please try again later.',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To get user office details
  useEffect(() => {
    if (filters.userId) {
      const fetchOfficeDetails = async () => {
        setLoading(true);
        try {
          const response = await getuserDetailsByTabName(token, tabName, filters.userId);
          setOfficeInformatinDetails(response?.data?.officeInformation);
        } catch (err) {
          console.error('Error fetching user personal details:', err);
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'Failed to fetch user company details',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            },
          });
        } finally {
          setLoading(false);
        }
      };

      fetchOfficeDetails();
    } else {
      setOfficeInformatinDetails(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.userId]);

  useEffect(() => {
    if (officeInformationDetails?.employeeCode) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        employeeId: officeInformationDetails.employeeCode,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        employeeId: '',
      }));
    }
  }, [officeInformationDetails]);

  useEffect(() => {
    if (filters.userId && filters.employeeId) {
      applyFilters();
    }
  }, [filters]);

  // To handle filter change
  const handleFilterChange = (e, value, field) => {
    if (field === 'username') {
      const selectedUser = users.find(user => user.fullName === value);
      setFilters({
        ...filters,
        userId: selectedUser ? selectedUser._id : '',
      });
    } else {
      setFilters({
        ...filters,
        [field]: value || ''
      });
    }
  };

  // To apply filters
  const applyFilters = () => {
    setTriggerFetch(prev => !prev);
  };

  // To clear filters
  const clearFilters = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
    const lastDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));

    setFilters({
      fromDate: firstDayOfWeek.toISOString().split('T')[0],
      toDate: lastDayOfWeek.toISOString().split('T')[0],
      specificDate: '',
      userId: '',
    });
    setDailyReportData([]);
    setAnchorEl(null);
  };

  // To open filter popover
  const openFilters = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // To close filter popover
  const closeFilters = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  const groupTasksByClient = (tasks) => {
    const groupedData = {};
    tasks.forEach(task => {
      const date = task.submittedDate;
      const company = task.company.trim().toUpperCase();

      if (!groupedData[date]) {
        groupedData[date] = {};
      }

      if (!groupedData[date][company]) {
        groupedData[date][company] = [];
      }
      groupedData[date][company].push(task);
    })
    return groupedData;
  };

  const groupedData = groupTasksByClient(dailyReportData);

  const handleDownload = async (tableData) => {
    setLoading(true);
    try {
      const response = await axios.post(`${backEndUrl}/download-daily-report-pdf`, tableData, { responseType: 'blob' });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        const fileName = taskData[0]?.employeeId ? `${taskData[0]?.employeeId}_${taskData[0]?.employeeName}_Task_Report_${filters?.fromDate}_to_${filters?.toDate}.pdf` : 'task_report.pdf';
        link.download = fileName;

        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.error('Failed to download PDF');
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download the PDF');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container'>
      <Box sx={{ padding: 2, maxHeight: '85vh', overflow: 'auto' }} className="custom-scrollbar">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 2 }}>
          <Button
            variant="outlined"
            onClick={openFilters}
            sx={{
              color: '#1b4965',
              borderColor: '#1b4965',
              borderRadius: '8px',
              padding: '8px 20px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#e0f7fa',
                borderColor: '#1b4965',
              },
            }}
          >
            Filters
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDownload(groupedData)}
            sx={{
              backgroundColor: '#4caf50',
              color: 'white',
              borderRadius: '8px',
              padding: '8px 20px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#45a049',
              },
            }}
          >
            Download
          </Button>
        </Box>
        <div style={{ padding: '20px', overflowX: 'auto', marginTop: '-20px' }}>
          <table id='tasksTable' style={{ width: '100%', borderCollapse: 'collapse', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
            <thead>
              <tr style={{ backgroundColor: '#1B4965', color: 'white', textAlign: 'center' }}>
                <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '6%' }}>Date</th>
                <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '5%' }}>Company</th>
                <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '80%' }}>Task Description</th>
                <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '4%' }}>Duration</th>
                <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '5%' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedData).map(([date, companies]) => {
                let totalMinutesForDate = 0;
                const totalTasksForDate = Object.values(companies).flat().length;

                return (
                  <React.Fragment key={date}>
                    {Object.entries(companies).map(([company, tasks], companyIndex) => {
                      const totalMinutesForCompany = tasks.reduce(
                        (acc, task) => acc + task.hours * 60 + task.minutes,
                        0
                      );
                      totalMinutesForDate += totalMinutesForCompany;
                      const totalHoursForCompany = Math.floor(totalMinutesForCompany / 60);
                      const remainingMinutesForCompany = totalMinutesForCompany % 60;

                      return (
                        <React.Fragment key={company}>
                          {tasks.map((task, index) => (
                            <tr key={task._id} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                              {index === 0 && companyIndex === 0 && (
                                <td rowSpan={totalTasksForDate + Object.keys(companies).length + 1}
                                  style={{ padding: '12px 5px', borderRight: '1px solid #ddd', backgroundColor: '#f7f7f7' }}>
                                  {date}
                                </td>
                              )}

                              {index === 0 && (
                                <td rowSpan={tasks.length + 1} style={{ padding: '12px 5px', borderRight: '1px solid #ddd', backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>
                                  {company}
                                </td>
                              )}
                              <td style={{ padding: '5px 5px', borderRight: '1px solid #ddd' }}>
                                {task.description}
                              </td>
                              <td style={{ padding: '5px 5px', borderRight: '1px solid #ddd' }}>
                                {`${task.hours}h ${task.minutes}m`}
                              </td>
                              <td style={{ padding: '5px 5px' }}>{task.status}</td>
                            </tr>
                          ))}
                          <tr style={{ backgroundColor: '#e0f7fa', fontWeight: 'bold', borderBottom: '1px solid #000' }}>
                            <td style={{ textAlign: 'right', padding: '10px', verticalAlign: 'middle', fontStyle: 'italic', fontSize: '14px' }}>
                              Total Hours for {company}:
                            </td>
                            <td colSpan={2} style={{ textAlign: 'left', padding: '10px', verticalAlign: 'middle', fontSize: '14px' }}>
                              {`${totalHoursForCompany}h ${remainingMinutesForCompany}m`}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                    <tr style={{ backgroundColor: '#d9edf7', fontWeight: 'bold', borderBottom: '2px solid #000' }}>
                      <td colSpan="2" style={{ textAlign: 'right', padding: '10px', verticalAlign: 'middle', fontSize: '16px' }}>
                        Total Hours for {date}:
                      </td>
                      <td colSpan={2} style={{ textAlign: 'left', padding: '10px', verticalAlign: 'middle', fontSize: '16px' }}>
                        {`${Math.floor(totalMinutesForDate / 60)}h ${totalMinutesForDate % 60}m`}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr style={{ backgroundColor: '#e6ffe6', fontWeight: 'bold', borderTop: '2px solid #000' }}>
                <td colSpan="3" style={{ textAlign: 'right', padding: '12px', verticalAlign: 'middle', fontSize: '18px' }}>
                  {`Total Hours for Selected Date Range: (${filters?.fromDate} - ${filters?.toDate})`}
                </td>
                <td style={{ textAlign: 'left', padding: '12px', verticalAlign: 'middle', fontSize: '18px' }} colSpan={2}>
                  {(() => {
                    const totalMinutes = Object.values(groupedData)
                      .flatMap((companies) =>
                        Object.values(companies).flat().map((task) => task.hours * 60 + task.minutes)
                      )
                      .reduce((acc, minutes) => acc + minutes, 0);
                    const totalHours = Math.floor(totalMinutes / 60);
                    const remainingMinutes = totalMinutes % 60;
                    return `${totalHours}h ${remainingMinutes}m`;
                  })()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
      <Popover open={isPopoverOpen} anchorEl={anchorEl} onClose={closeFilters} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} PaperProps={{ style: { borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)' } }}>
        <FilterBox>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField label="From Date" type="date" name="fromDate" InputLabelProps={{ shrink: true }} value={filters.fromDate} onChange={(e) => setFilters({ ...filters, fromDate: e.target.value })} />
            <TextField label="To Date" type="date" name="toDate" InputLabelProps={{ shrink: true }} value={filters.toDate} onChange={(e) => setFilters({ ...filters, toDate: e.target.value })} />
          </Box>
          <Autocomplete options={[...users.map(user => user.fullName)]} renderInput={(params) => <TextField {...params} label="Username" />} value={filters.username} onChange={(e, value) => handleFilterChange(e, value, 'username')} clearOnEscape />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
            <Button variant="outlined" onClick={clearFilters} sx={{ color: '#1b4965', borderColor: '#1b4965' }}>Clear Filters</Button>
            <Button variant="outlined" onClick={closeFilters} sx={{ color: '#ff5722', borderColor: '#ff5722' }}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={applyFilters} sx={{ backgroundColor: '#4caf50', color: 'white' }}>Apply Filters</Button>
          </Box>
        </FilterBox>
      </Popover>
      {loading && <Loader />}
    </div>
  );
}

export default Reports;

const FilterBox = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  min-width: 300px;
`;