import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getAllUsersDetails } from '../../../../utils/Services/userServices';
import { getLeaveTypes } from '../../../../utils/Services/leaveServices';
import LeaveHistory from './LeaveHistory';

function LeaveInformation() {
    const [usersData, setUsersData] = useState([]);
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [viewHistoryUser, setViewHistoryUser] = useState()
    const [viewHistoryuserModal, setViewHistoryuserModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    useEffect(() => {
        fetchUserDetail();
    }, []);
    useEffect(() => {
        fetchleaveTypeDetail();
    }, []);

    const fetchUserDetail = async () => {
        try {
            setLoading(true);
            const response = await getAllUsersDetails(token, subscriberId);

            const activeUsers = (response?.data?.data || []).filter(user => user.isActive);

            const sortedData = activeUsers.sort((a, b) =>
                (a?.officeInformation?.employeeCode || '').localeCompare(
                    b?.officeInformation?.employeeCode || '',
                    undefined,
                    { numeric: true }
                )
            );

            setUsersData(sortedData);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error fetching data.',
                confirmButtonColor: '#1B4965'
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchleaveTypeDetail = async () => {
        setLoading(true);
        try {
            const response = await getLeaveTypes(token, subscriberId);
            setLeaveTypes(response?.data?.leaveTypes);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch data',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            })
        } finally {
            setLoading(false);
        }
    }

    const handleViewHistory = (user) => {
        setViewHistoryUser(user);
        setViewHistoryuserModal(true);
    }

    return (
        <div style={{ padding: '20px', height: '70vh', overflowY: 'auto' }}>
            <TableContainer component={Paper} style={{ marginTop: "20px", boxShadow: "3px 3px 10px rgba(0,0,0,0.1)", borderRadius: "10px", overflow: "hidden" }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell style={{ fontWeight: "bold" }}>Employee ID</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Employee Name</TableCell>
                            {leaveTypes.map((leave) => (
                                <TableCell key={leave._id} style={{ fontWeight: "bold" }}>
                                    {leave.leaveTypeName}
                                </TableCell>
                            ))}
                            <TableCell style={{ fontWeight: "bold" }}>View History</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersData.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>{user?.officeInformation?.employeeCode || '-'}</TableCell>
                                <TableCell>{user?.fullName || '-'}</TableCell>
                                {leaveTypes.map((leave) => (
                                    <TableCell key={leave._id}>0</TableCell>
                                ))}
                                <TableCell>
                                    <Typography
                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#1B4965' }}
                                        onClick={() => handleViewHistory(user)}
                                    >
                                        View History
                                        <ArrowForwardIcon style={{ marginLeft: '5px' }} />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <LeaveHistory open={viewHistoryuserModal} onClose={() => setViewHistoryuserModal(false)} userData={viewHistoryUser} leaveTypes={leaveTypes} />
            {(loading) && (
                <Loader />
            )}
        </div>
    )
}

export default LeaveInformation