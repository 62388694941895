import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, Modal, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { getLeaveBalance } from '../../../../utils/Services/leaveServices';

function LeaveHistory({ open, onClose, userData, leaveTypes }) {
    const [selectedYear, setSelectedYear] = useState(dayjs().year());
    const [editableCell, setEditableCell] = useState(null);
    const [leaveBalanceData, setLeaveBalanceData] = useState([]);
    const [leaveValues, setLeaveValues] = useState({});
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);

    useEffect(() => {
        if (userData && open) {
            fetchLeaveBalance();
        }
    }, [userData, selectedYear, open]);

    const fetchLeaveBalance = async () => {
        try {
            setLoading(true);
            const response = await getLeaveBalance(token, userData._id, selectedYear);
            setLeaveBalanceData(response?.data?.data);
        } catch (error) {
            console.error('Failed to fetch leave balance:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCellClick = (rowIndex, colIndex) => {
        setEditableCell({ rowIndex, colIndex });
    };

    const handleInputChange = (event, rowIndex, colIndex) => {
        const value = event.target.value.replace(/\D/g, "");
        setLeaveValues((prev) => ({
            ...prev,
            [`${rowIndex}-${colIndex}`]: value,
        }));
    };

    const handleBlur = () => {
        setEditableCell(null);
    };

    const handleUpdateRecord = () => {
        console.log("Updated Leave Balances:", leaveValues);
    };

    const monthColors = ['#E3F2FD', '#FFEBEE'];

    return (
        <>
            <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: 5 }}>
                <Box sx={{ width: '80%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, overflowY: 'auto' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography variant="h6" sx={{ color: '#1B4965' }}>Leave Balance History</Typography>
                        <Button onClick={onClose} variant="contained" sx={{ backgroundColor: '#1B4965', color: 'white' }}>
                            Back
                        </Button>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker views={['year']} label="Select Year"
                                value={dayjs().year(selectedYear)}
                                onChange={(newValue) => setSelectedYear(newValue.year())}
                            />
                        </LocalizationProvider>
                    </Box>

                    <Button onClick={handleUpdateRecord} variant="contained" sx={{ backgroundColor: "#1B4965", color: "white" }}>
                        Update Record
                    </Button>

                    <TableContainer component={Paper} sx={{ marginTop: 2, boxShadow: 3, borderRadius: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1B4965', width: '30%', color: 'whitesmoke' }}>
                                        {userData?.fullName} : {userData?.officeInformation?.employeeCode}
                                    </TableCell>
                                    {leaveTypes.map((leaveType, index) => (
                                        <TableCell key={index} sx={{ fontWeight: 'bold', backgroundColor: '#1B4965', textAlign: 'center', color: 'whitesmoke' }}>
                                            {leaveType.leaveTypeName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>
                                        End of December {selectedYear - 1}
                                    </TableCell>
                                    {leaveTypes.map((leaveType, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{ textAlign: "center", cursor: "pointer" }}
                                            onClick={() => handleCellClick(0, index)}
                                        >
                                            {editableCell?.rowIndex === 0 && editableCell?.colIndex === index ? (
                                                <TextField
                                                    value={leaveValues[`${0}-${index}`] || ""}
                                                    onChange={(e) => handleInputChange(e, 0, index)}
                                                    onBlur={handleBlur}
                                                    autoFocus
                                                    sx={{ width: "60px" }}
                                                />
                                            ) : (
                                                leaveValues[`${0}-${index}`] || "-"
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                {Array.from({ length: 12 }).map((_, monthIndex) => {
                                    const monthName = dayjs().month(monthIndex).format('MMMM');
                                    const currentMonth = dayjs().month();
                                    const currentYear = dayjs().year();

                                    const isPastYear = selectedYear < currentYear;
                                    const isCurrentYear = selectedYear === currentYear;
                                    const isMonthVisible = isPastYear || (isCurrentYear && monthIndex <= currentMonth);

                                    if (isMonthVisible) {
                                        const backgroundColor = monthColors[monthIndex % 2];

                                        return (
                                            <>
                                                <TableRow key={monthIndex}>
                                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor }}>
                                                        Added for {monthName} {selectedYear}
                                                    </TableCell>
                                                    {leaveTypes.map((leaveType, index) => {
                                                        const balance = leaveBalanceData?.find(l => l.leaveTypeId === leaveType.leaveTypeId && l.year === selectedYear && l.month === monthIndex + 1)?.balance || 0;
                                                        return (
                                                            <TableCell key={index} sx={{ textAlign: 'center', backgroundColor }}>
                                                                {balance}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>

                                                <TableRow key={monthIndex + 100}>
                                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor }}>
                                                        Used in {monthName} {selectedYear}
                                                    </TableCell>
                                                    {leaveTypes.map((leaveType, index) => {
                                                        const used = leaveBalanceData?.find(l => l.leaveTypeId === leaveType.leaveTypeId && l.year === selectedYear && l.month === monthIndex + 1)?.used || 0;
                                                        return (
                                                            <TableCell key={index} sx={{ textAlign: 'center', backgroundColor }}>
                                                                {used}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>

                                                <TableRow key={monthIndex + 200}>
                                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor }}>
                                                        End of {monthName} {selectedYear}
                                                    </TableCell>
                                                    {leaveTypes.map((leaveType, index) => {
                                                        const balance = leaveBalanceData?.find(l => l.leaveTypeId === leaveType.leaveTypeId && l.year === selectedYear && l.month === monthIndex + 1)?.balance || 0;
                                                        return (
                                                            <TableCell key={index} sx={{ textAlign: 'center', backgroundColor }}>
                                                                {balance}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </>
                                        );
                                    }
                                    return null;
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
            {loading && <Loader />}
        </>
    );
}

export default LeaveHistory;