import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { ChromePicker } from 'react-color';

import { selectToken } from '../../../../../../Redux/selector';
import Loader from '../../../../../UiUxComponents/Loader/Loader';
import { createLeaveTypes } from '../../../../../../utils/Services/leaveServices';

function CreateLeaveTypes({ open, onClose }) {
  const [leaveTypeName, setLeaveTypeName] = useState('');
  const [description, setDescription] = useState('');
  const [leaveTypeColor, setLeaveTypeColor] = useState('');
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  const resetState = () => {
    setLeaveTypeName('');
    setDescription('');
    setLeaveTypeColor('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!leaveTypeName || !description || !leaveTypeColor) {
      Swal.fire({
        title: 'Required',
        text: 'Please enter all the required fields.',
        icon: 'warning',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      return;
    }
    const data = { leaveTypeName, description, leaveTypeColor };
    try {
      setLoading(true);
      await createLeaveTypes(token, subscriberId, data);
      Swal.fire({
        icon: 'success',
        title: 'Leave Type Created Successfully',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
      onClose();
    } catch (err) {
      console.error('Error creating leave type:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to create leave type',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      resetState();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Box sx={{ width: '25%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative', left: 0, transition: 'transform 0.3s ease-in-out', transform: open ? 'translateX(0)' : 'translateX(-100%)', overflowY: 'auto', }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '15px' }}>
            <Typography variant="h6" sx={{ color: '#1B4965' }}>Create Leave Type</Typography>
            <Button onClick={onClose} variant="contained" sx={{ backgroundColor: '#1B4965', color: 'white', '&:hover': { backgroundColor: '#1B4965' }, fontSize: '12px' }}>
              Back
            </Button>
          </Box>
          <form onSubmit={handleSubmit} style={{ marginTop: '40px' }}>
            <TextField label="Leave Type Name" variant="outlined" fullWidth required
              sx={{ marginBottom: 2 }}
              value={leaveTypeName}
              onChange={(e) => setLeaveTypeName(e.target.value)}
            />
            <TextField label="Description" variant="outlined" fullWidth required multiline rows={4}
              sx={{ marginBottom: 2 }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Typography sx={{ marginBottom: 1 }}>Leave Type Color</Typography>
            <ChromePicker color={leaveTypeColor || '#FFFFFF'}
              onChangeComplete={(color) => setLeaveTypeColor(color.hex)}
              disableAlpha={true}
              style={{ marginBottom: '20px' }}
            />
            <Button type="submit" variant="contained" disabled={!leaveTypeColor}
              sx={{
                backgroundColor: '#1B4965',
                color: 'white',
                '&:hover': { backgroundColor: '#1B4965' },
                fontSize: '14px',
                width: '40%',
                marginTop: '20px',
                float: 'right',
              }}
            >Create
            </Button>
          </form>
        </Box>
      </Modal>
      {(loading) && (
        <Loader />
      )}
    </>
  );
}

export default CreateLeaveTypes;
