import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Swal from "sweetalert2";
import { Box, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import "./Login.css";
import Applogo from '../../../assets/NavbarImages/app-logo-color.png'
import Wave from "../../../assets/Login/wave.svg";
import LoginImg from "../../../assets/Login/bg2.svg";
import ClientLoginImg from "../../../assets/Login/bg3.svg"
import { Roles } from '../../../utils/constant'
import Loader from "../../../Components/UiUxComponents/Loader/Loader";
import { setUser } from "../../../Redux/reducers/authReducer";
import { loginUser } from '../../../utils/Services/authServices'
import { currentStatus } from '../../../utils/Services/statusServices'

function Login() {
  const [isClientLogin, setIsClientLogin] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Handle Change Company Code
  const handleCompanyCodeChange = (event) => {
    setCompanyCode(event.target.value);
  };

  // Handle Change Email
  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  // Handle Change Password
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Handle Password Visibility Toggle
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Toggle between Client Login and Subscriber Login
  const handleToggleLoginMode = () => {
    setIsClientLogin(!isClientLogin);
    setCompanyCode("");

    if (!isClientLogin) {
      navigate('/client-login');
    } else {
      navigate('/');
    }
  };

  // const loginOtpPath = location.pathname.startsWith('/client-login')
  //   ? '/client-login-with-otp'
  //   : '/login-with-otp';

  const forgotPasswordPath = location.pathname.startsWith('/client-login')
    ? '/client-forgot-password'
    : '/forgot-password';

  // Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { companyCode: companyCode, userEmail: userEmail, password: password, deviceType: 'web' };

    if (!formData.companyCode || !formData.userEmail || !formData.password) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill all the details',
        confirmButtonColor: "#1B4965",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await loginUser(formData);
      const onlineData = { icon: "", label: "Active", time: "" }
      await currentStatus(onlineData, response.data.user._id);
      Swal.fire({
        icon: 'success',
        title: 'Login Successfully',
        text: `Welcome ${response.data.user.fullName}`,
        showConfirmButton: false,
        timer: 1500,
      });
      dispatch(setUser({ user: response.data.user, token: response.data.token }));
      if (response.data.user.role === Roles.SYSTEM_SUPER_ADMIN) {
        navigate("/systemadmin");
      } else if ((response?.data?.user?.role === Roles.COMPANY_SUPER_ADMIN || response?.data?.user?.role === Roles.USERS) &&
        response?.data?.user?.companyId?.companyRole === Roles.COMPANY_SUBSCRIBER) {
        navigate(`/user/${response.data.user._id}`)
      } else if ((response?.data?.user?.role === Roles.COMPANY_SUPER_ADMIN || response?.data?.user?.role === Roles.USERS) &&
        response?.data?.user?.companyId?.companyRole === Roles.COMPANY_CLIENT) {
        navigate(`/client/${response.data.user._id}`)
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Role Dashboard Not Created',
          text: 'The dashboard for this role is not yet created.',
          confirmButtonColor: "#1B4965",
        });
      }
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: err?.response?.data?.msg,
        text: err.response ? err.response.data.message : 'Something went wrong',
        confirmButtonColor: "#1B4965",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="logincontainer">
      <img src={Wave} alt="" className="wave" />
      <div className="login-container-form">
        <div className="login-img" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={location.pathname.startsWith('/client-login') ? ClientLoginImg : LoginImg} alt="" />
        </div>
        <div className="login-form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
          <Button
            variant="contained"
            onClick={handleToggleLoginMode}
            style={{ position: 'absolute', top: 10, right: 10, backgroundColor: '#1B4965', color: '#fff', borderRadius: '20px', fontSize: '0.9rem', padding: '8px 16px', textTransform: 'none' }}
          >
            {location.pathname.startsWith('/client-login') ? 'Subscriber Login' : 'Client Login'}
          </Button>
          <div className="login-form-container">
            <img src={Applogo} alt="" style={{ height: '100px' }} />
            <form onSubmit={handleSubmit} style={{ marginLeft: '0%', width: 'calc(100% - 0px)' }}>
              <Box>
                <TextField label="Company Code" variant="outlined" fullWidth margin="normal" onChange={handleCompanyCodeChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#051925",
                      },
                    },
                  }}
                />
              </Box>
              <Box>
                <TextField label="Work Email" variant="outlined" fullWidth margin="normal" onChange={handleEmailChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#051925",
                      },
                    },
                  }}
                />
              </Box>
              <Box>
                <TextField label="Password" variant="outlined" type={showPassword ? "text" : "password"} fullWidth margin="normal" onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleClickShowPassword} size="small">
                          {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&:hover fieldset": {
                        borderColor: "#1B4965",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#051925",
                      },
                    },
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                {/* <Link to={loginOtpPath} className="loginotp">Login With OTP</Link> */}
                <Link to={forgotPasswordPath} className="forgotpassword">Forgot Password?</Link>
              </Box>
              <Button type="submit" variant="contained" fullWidth
                style={{ width: "calc(100% - 0px)", borderRadius: "25px", outline: "none", border: "none", backgroundColor: '#1B4965', fontSize: "1.2rem", color: "#fff", fontFamily: "'Poppins', sans-serif", textTransform: "uppercase", margin: "1rem 0", cursor: "pointer", transition: "0.5s", }}>
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
      {(loading) && (
        <Loader />
      )}
    </div>
  );
}

export default Login;
