import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { ListItemIcon, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import './LeaveType.css'
import { LeaveTypeTypes } from '../../../../../types';
import { selectToken } from '../../../../../Redux/selector';
import Pagination from '../../../../Pagination/Pagination';
import Loader from '../../../../UiUxComponents/Loader/Loader';
import CreateLeaveTypes from './LeaveTypesComponents/CreateLeaveTypes';
import ViewLeaveType from './LeaveTypesComponents/ViewLeaveType';
import { deleteLeaveType, getLeaveTypes } from '../../../../../utils/Services/leaveServices';

function LeaveType() {
  const [data, setData] = useState<LeaveTypeTypes[]>([]);
  const [openModal, setLeaveTypeRegistrationModal] = useState<boolean>(false);
  const [viewLeaveTypeModal, setViewLeaveTypeModal] = useState<boolean>(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveTypeTypes | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  const fetchleaveTypeDetail = async () => {
    setLoading(true);
    try {
      const response = await getLeaveTypes(token, subscriberId);
      setData(response?.data?.leaveTypes);
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch data',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      })
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchleaveTypeDetail();
  }, []);
  useEffect(() => {
    if (!openModal && !viewLeaveTypeModal) {
      fetchleaveTypeDetail();
    }
  }, [openModal, viewLeaveTypeModal]);

  const openLeaveTypeRegistrationModal = () => {
    setLeaveTypeRegistrationModal(true);
  };

  const closeLeaveTypeRegistrationModal = () => {
    setLeaveTypeRegistrationModal(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteLeaveTypeById = async (leaveTypeId: string | undefined) => {
    if (leaveTypeId) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1B4965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          container: 'swal-container',
        }
      });

      if (result.isConfirmed) {
        setLoading(true);
        try {
          await deleteLeaveType(token, leaveTypeId);
          Swal.fire({
            icon: 'success',
            title: 'Leave Type Deleted Successfully',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } catch (error) {
          console.error('Error leave type:', error);
          Swal.fire({
            icon: 'error',
            title: 'Failed to leave type',
            confirmButtonColor: '#1B4965',
            customClass: {
              container: 'swal-container',
            }
          });
        } finally {
          setLoading(false);
          fetchleaveTypeDetail();
        }
      }
    }
  };

  const columns = useMemo<MRT_ColumnDef<LeaveTypeTypes>[]>(
    () => [
      { accessorKey: 'leaveTypeName', header: 'leave Type' },
      { accessorKey: 'description', header: 'Description' },
      { accessorKey: 'leaveTypeColor', header: 'Leave Type Color' },
    ], []
  );

  const muiTableHeadCellProps = {
    sx: {
      backgroundColor: '#1B4965',
      color: '#fff',
      fontWeight: 'bold',
      '& .MuiTableSortLabel-root .MuiTableSortLabel-icon': {
        color: '#fff !important',
      },
      '& .MuiTableSortLabel-icon': {
        color: '#fff',
      },
      '& .MuiIconButton-root': {
        color: '#fff',
      },
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '12px',
      },
      fontSize: '14px'
    },
  };

  const muiTableBodyRowProps = (row: any) => ({
    sx: {
      backgroundColor: getRowBackgroundColor(row),
    }
  })

  const muiTableBodyCellProps = (row: any) => ({
    sx: {
      fontSize: '13px',
    }
  })

  const getRowBackgroundColor = (row: any) => {
    return row?.row?.index % 2 === 0 ? "#ffffff" : "#d6e1ed"
  };

  const table = useMaterialReactTable({
    columns,
    data: data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    enableTopToolbar: false,
    muiTableHeadCellProps,
    muiTableBodyRowProps,
    muiTableBodyCellProps,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: false,
    enableRowActions: true,
    renderBottomToolbarCustomActions: () => (
      <Pagination
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    ),
    initialState: {
      density: 'comfortable',
      columnPinning: { right: ['mrt-row-actions'] }
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        zIndex: -1,
      },
    }),
    muiTableContainerProps: { sx: { maxHeight: '65vh' } },
    renderRowActionMenuItems: ({ row, closeMenu }) => {
      const { _id } = row.original;
      return [
        <MenuItem
          key={0}
          onClick={() => {
            setSelectedLeaveType(row?.original);
            setViewLeaveTypeModal(true);
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <AccountCircle style={{ fontSize: '18px' }} />
          </ListItemIcon>
          View Leave Type
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            deleteLeaveTypeById(_id)
            closeMenu();
          }}
          sx={{ m: 0, fontSize: '13px' }}
        >
          <ListItemIcon>
            <DeleteForeverIcon style={{ fontSize: '18px' }} />
          </ListItemIcon>
          Delete Leave Type
        </MenuItem>,
      ];
    },
  })

  return (
    <div>
      <div className='leavetype-dashboard'>
        <div className='leavetype-title'>
          <p>Leave Types</p>
        </div>
        <button className='add-department' onClick={openLeaveTypeRegistrationModal}>Create New</button>
      </div>
      <div className='leavetypetable'>
        <MaterialReactTable table={table} />
      </div>
      <CreateLeaveTypes open={openModal} onClose={closeLeaveTypeRegistrationModal} />
      <ViewLeaveType open={viewLeaveTypeModal} onClose={() => setViewLeaveTypeModal(false)} leaveType={selectedLeaveType} />
      {(loading) && (
        <Loader />
      )}
    </div>
  )
}

export default LeaveType